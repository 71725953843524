<div [formGroup]="group" class="emc-box">
  <div class="head">
    <span class="space-filler"></span>
    <button (click)="onDelete()" mat-icon-button>
      <mat-icon color="warn" matTooltip="Delete">delete</mat-icon>
    </button>
  </div>
  <div class="elements">
    <p class="label">Question</p>
    <mat-form-field floatLabel="never">
      <input formControlName="title" matInput placeholder="Title" type="text" />
    </mat-form-field>
    <mat-checkbox
      color="primary"
      formControlName="has_textfield"
      style="margin-left: 10px"
    >
      Show Textfield
    </mat-checkbox>
    <mat-checkbox
      color="primary"
      formControlName="multiple_select"
      style="margin-left: 10px"
    >
      Select Multiple
    </mat-checkbox>
    <mat-checkbox
      color="primary"
      formControlName="required"
      style="margin-left: 10px"
    >
      Required
    </mat-checkbox>
  </div>
  <ng-container *ngIf="templateId !== 63 && !group.get('has_textfield').value">
    <div class="elements">
      <p class="label">Options</p>
      <tag-input [modelAsStrings]="true" formControlName="options"></tag-input>
    </div>
  </ng-container>
</div>
