export enum AppPath {
  DRHortonCLTHERO = "DRHortonCLTHERO",
  StanleyMartinSafety = "StanleyMartinSafety",
  JacksonvilleEnergy = "Dreamfinders/Jacksonville-Energy",
  MIHomesEnergy = "MI-Homes/energy",
  DreamFinderOrlando = "Dreamfinders/Orlando-Energy-1589",
  DreamFindersTampa = "Dreamfinders/Orlando-Energy-1349",
  MeritageJacksonvilleEnergy = "Meritage/Jacksonville-Energy-1725",
  DreamFindersActiveAdultJAX = "Dreamfinders/Active-Adult-JAX-Energy-1548",
  leads = "leads",
  privacy_policy = "privacy-policy",
  auth = "auth",
  dashboard = "dashboard",
  rain_log = "rain-log",
  home = "home",
  inspections = "inspections",
  clients = "clients",
  projects = "projects",
  users = "users",
  tools = "tools",
  requests_reports = "requests-reports",
  email_change = "email-change",
  external_links = "external-links",
  profile = "user-profile",
  project_stop_requests = "project-stop-request",
  compliance_requests = "compliance-request",
  status_reporting = "status-reporting",
  trouble_ticket = "trouble-ticket",
  admin_upload_reports = "upload-reports",
  project_activity_reports = "project-activity-reports"
}

export const AppTitle = {
  [AppPath.rain_log]: "Rain Log",
  [AppPath.leads]: "Leads",
  [AppPath.privacy_policy]: "Privacy Policy",
  [AppPath.dashboard]: "Dashboard",
  [AppPath.home]: "Home",
  [AppPath.inspections]: "Inspections",
  [AppPath.clients]: "Clients",
  [AppPath.projects]: "Projects",
  [AppPath.users]: "Users",
  [AppPath.tools]: "Tools",
  [AppPath.requests_reports]: "Requests & Reports",
  [AppPath.external_links]: "External Links",
  [AppPath.profile]: "User Profile",
  [AppPath.project_stop_requests]: "Project Stop Requests",
  [AppPath.compliance_requests]: "Compliance Requests",
  [AppPath.status_reporting]: "Status Reporting",
  [AppPath.trouble_ticket]: "Trouble Ticket",
  [AppPath.admin_upload_reports]: "Upload Reports",
  [AppPath.project_activity_reports]: "Project Activity Reports",
  [AppPath.email_change]: "Email Change Requests"
};
