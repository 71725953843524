import { AbstractControl, FormArray, FormGroup } from "@angular/forms";
import {
  isValidNumber,
  parse,
  ParsedNumber,
  parsePhoneNumber
} from "libphonenumber-js";
import { ISelectOption } from "@emc-modules/shared/components/emc-search-field/emc-search-field.component";
import { TitleCasePipe } from "@angular/common";
import { UnSlugifyPipe } from "@emc-modules/shared/pipes/unslugify.pipe";
import { UserNameOnly } from "@emc-models/entities/user.model";
import { ProjectNameOnly } from "@emc-models/entities/project.model";
import { ClientNameOnly } from "@emc-models/entities/client.model";
import { Options } from "@angular-slider/ngx-slider";
import * as momentT from "moment-timezone";
import { Moment } from "moment-timezone";
import { AppPath } from "../app.path";
import * as _ from "lodash";
import { DynamicQuestionType } from "./constants";

export class CommonUtils {
  static readonly XS = "xs";
  static readonly SM = "sm";
  static readonly MD = "md";
  static readonly LG = "lg";
  static readonly XL = "xl";

  static readonly ASC = "asc";
  static readonly DESC = "desc";

  static markControlsAsTouched(control: AbstractControl | AbstractControl[]) {
    const abstractControls = [].concat(control);
    abstractControls.forEach(abstractControl => {
      abstractControl.markAsTouched();

      if (abstractControl instanceof FormGroup) {
        const controls = Object.values(abstractControl.controls);
        CommonUtils.markControlsAsTouched(controls);
      } else if (abstractControl instanceof FormArray) {
        CommonUtils.markControlsAsTouched(abstractControl.controls);
      }
    });
    this.scrollToError();
  }

  static generateRandomNumber(max: number, min?: number) {
    let difference = max - min;

    let rand = Math.random();

    rand = Math.floor(rand * difference);

    rand = rand + min;

    return rand;
  }

  static enumerateDaysBetweenDates = function (
    startDate: string,
    endDate?: string
  ) {
    let dateArray = [];

    // convert input date to specified timezone
    const dateStart = momentT
      .tz(startDate, "YYYY-MM-DD", "America/New_York")
      .add(1, "days")
      .startOf("day");
    let dateEnd: Moment;
    if (endDate) {
      dateEnd = momentT
        .tz(endDate, "YYYY-MM-DD", "America/New_York")
        .startOf("day");
    } else {
      dateEnd = momentT.tz("America/New_York").startOf("day");
    }
    console.log(
      dateStart.toDate(),
      dateEnd.toDate(),
      dateEnd.diff(dateStart, "hours")
    );
    // create an array of dates from startDate to yesterday
    while (
      dateStart.isBefore(dateEnd) &&
      dateEnd.diff(dateStart, "hours") > 12
    ) {
      dateArray.push(dateStart.format("YYYY-MM-DD"));
      dateStart.add(1, "days");
    }
    return dateArray.reverse();
  };

  static getSizeClass() {
    const width = window.innerWidth;
    if (width >= 1920) {
      return CommonUtils.XL;
    } else if (width >= 1280) {
      return CommonUtils.LG;
    } else if (width >= 960) {
      return CommonUtils.MD;
    } else if (width >= 600) {
      return CommonUtils.SM;
    } else {
      return CommonUtils.XS;
    }
  }

  static getFileType(type: string): string {
    switch (type) {
      case "application/pdf": {
        return "PDF";
      }
      case "application/zip":
      case "application/x-compressed-zip": {
        return "Compressed";
      }
      case "text/plain":
      case "text/csv":
      case "application/vnd.ms-excel":
      case "application/msword":
      case "application/octet-stream": {
        return "Document";
      }
      default: {
        if (type.includes("image")) {
          return "Image";
        } else if (type.includes("audio")) {
          return "Audio";
        }
      }
    }
    return "Other";
  }

  static sortByKey(items: any[], key: string, direction = "asc") {
    if (!key || !direction) {
      return items;
    }

    return [...items].slice().sort((a, b) => {
      const propertyA: number | string = a[key];
      const propertyB: number | string = b[key];

      const valueA = isNaN(+propertyA) ? propertyA : +propertyA;
      const valueB = isNaN(+propertyB) ? propertyB : +propertyB;

      return (valueA < valueB ? -1 : 1) * (direction === "asc" ? 1 : -1);
    });
  }

  static normalize(entityArray: any[]) {
    const result = {};
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < entityArray.length; i++) {
      result[entityArray[i].id] = entityArray[i];
    }

    return result;
  }

  static normalizedObjToArray(object: { [id: string]: any }) {
    const result = [];
    for (let i = 1; i <= Object.keys(object).length; i++) {
      result.push(object[i]);
    }

    return result;
  }

  static getObjectValues(object: { [id: number]: any }) {
    const values = [];
    for (const key in object) {
      if (object.hasOwnProperty(key)) {
        values.push(object[key]);
      }
    }
    return values;
  }

  static iterateEnum<T>(enumRef: any): T[] {
    return Object.keys(enumRef).map(key => enumRef[key]);
  }

  static checkInEnum<T>(enumRef: any, value: T): boolean {
    return (
      Object.keys(enumRef)
        .filter(k => isNaN(Number(k))) // Removing reverse mapping in numeric enums.
        .filter(k => enumRef[k] === value).length > 0
    );
  }

  static enumToISelect(enumRef: any): ISelectOption[] {
    const titleCasePipe = new TitleCasePipe();
    const unslugifyPipe = new UnSlugifyPipe();

    return CommonUtils.iterateEnum(enumRef).map((e: string) => {
      return {
        title: titleCasePipe.transform(unslugifyPipe.transform(e)),
        value: e
      };
    });
  }

  static hasAssignedUser(
    users: UserNameOnly[],
    userId: number | string
  ): boolean {
    if (users && users.length && userId) {
      return !!users.find(u => u.id === userId);
    }
    return false;
  }

  static hasAssignedProject(
    projects: ProjectNameOnly[],
    projectId: number | string
  ): boolean {
    if (projects && projects.length && projectId) {
      return !!projects.find(u => u.id === projectId);
    }
    return false;
  }

  static hasAssignedClient(
    clients: ClientNameOnly[],
    clientId: number | string
  ): boolean {
    if (clients && clients.length && clientId) {
      return !!clients.find(u => u.id === clientId);
    }
    return false;
  }

  static scrollTo(el: Element): void {
    if (el) {
      el.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  }

  static isOptionsVisible(type: string) {
    return _.includes(
      [
        DynamicQuestionType.BUTTON_TOGGLE,
        DynamicQuestionType.MULTISELECT_DROPDOWN,
        DynamicQuestionType.MULTI_BUTTON_TOGGLE,
        DynamicQuestionType.DROPDOWN
      ],
      type
    );
  }

  static scrollToError(element: string = ".ng-invalid:not(form)"): void {
    const firstElementWithError = document.querySelector(element);

    CommonUtils.scrollTo(firstElementWithError);
  }

  static parseToFloat(value: any) {
    return value ? parseFloat(value) : 0.0;
  }

  static parseToInt(value: any) {
    return value ? parseInt(value, 10) : 0;
  }

  static toISODateString(date: Date): string {
    const fullDate = ("0" + date.getDate()).slice(-2);
    const fullMonth = ("0" + (date.getMonth() + 1)).slice(-2);
    const fullYear = date.getFullYear();

    return fullYear + "-" + fullMonth + "-" + fullDate;
  }

  static getFormData(data, attachmentKeyName?: string): FormData {
    console.log(data, attachmentKeyName);
    const formData = new FormData();
    Object.keys(data).forEach(key => {
      if (key === attachmentKeyName) {
        for (const attachment of data[key]) {
          formData.append(key, attachment);
        }
      } else {
        formData.append(key, data[key]);
      }
    });
    return formData;
  }

  static dataURItoFile(dataURI) {
    try {
      const binary = atob(dataURI.split(",")[1]);
      const array = [];
      for (let i = 0; i < binary.length; i++) {
        array.push(binary.charCodeAt(i));
      }
      return new Blob([new Uint8Array(array)], {
        type: "image/svg"
      });
    } catch (e) {
      return null;
    }
  }

  static randomString(length = 5): string {
    let result = "";
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }
}

interface Entity {
  id: number;
}

export function ValidateMobileNumber(control: AbstractControl) {
  if (control.value && !valid(control.value)) {
    return { validNo: true };
  } else if (control.value && valid(control.value)) {
    return null;
  }
  return null;
}

function valid(number: string) {
  const parsedNumber: ParsedNumber = parse(number);
  return isValidNumber(number, parsedNumber.country || "US");
}

export const editorOptions = {
  toolbar: [
    "heading",
    "|",
    "bold",
    "italic",
    "link",
    "bulletedList",
    "numberedList",
    "blockQuote",
    "inserttable",
    "undo",
    "redo",
    "indent",
    "outdent"
  ]
};

export const SLIDER_OPTIONS: Options = {
  floor: 1,
  ceil: 10,
  step: 1,
  showTicks: true,
  showTicksValues: true
};

export const alertTypes = [
  {
    title: "On",
    value: true
  },
  {
    title: "Off",
    value: false
  }
];

export const booleanOptions = [
  {
    title: "Yes",
    value: true
  },
  {
    title: "No",
    value: false
  }
];

export const directAccessUrlsWithoutLogin = [
  "/" + AppPath.DRHortonCLTHERO,
  "/" + AppPath.StanleyMartinSafety,
  "/" + AppPath.JacksonvilleEnergy,
  "/" + AppPath.MeritageJacksonvilleEnergy,
  "/" + AppPath.MIHomesEnergy,
  "/" + AppPath.DreamFinderOrlando,
  "/" + AppPath.DreamFindersTampa,
  "/" + AppPath.DreamFindersActiveAdultJAX
];
