import { Component, OnInit } from "@angular/core";

@Component({
  selector: "ae-meritage-jacksonville-energy",
  templateUrl: "./meritage-jacksonville-energy.component.html",
  styleUrls: ["./meritage-jacksonville-energy.component.scss"]
})
export class MeritageJacksonvilleEnergyComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
